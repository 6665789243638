import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const AboutText = styled.p`
  max-width: 550px;
`;

function AboutPage() {
  return (
    <>
      <SEO title="About us" />
      <Layout>
        <section className="explore-container">
          <h1>Idea behind Zooticket</h1>
          <AboutText>
            It has never been easy, to explore events all around UK.
            <br /> Especially not, if you aren't aware of, what is happening
            already.
          </AboutText>
          <AboutText>
            Are you visiting friends or family in a specific city, and would
            like to know what is possible to explore? It could be, you would
            like to know, what you can explore with friends on your birthday?
          </AboutText>
          <AboutText>
            This is our vision. To help you! Our goal is to make this easy, with
            the use of Zooticket. We are collecting events from different ticket
            providers. At the same time, we have made it possible, to sort
            events by dates, categories and cities. You can even search for
            keywords.
          </AboutText>
        </section>
      </Layout>
    </>
  );
}

export default AboutPage;
